export const logo = ['608 134', `
  <title>coreui react pro</title>
  <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m1070 482 c0 -4 12 -8 28 -8 15 -1 52 -5 82 -9 30 -5 69 -8 87 -8 18 0
31 -5 30 -10 -3 -14 66 -28 74 -16 3 6 30 10 60 10 30 1 59 5 66 11 7 5 22 6
35 2 13 -5 32 -9 43 -10 11 -1 38 -5 60 -9 22 -4 58 -8 80 -9 75 -5 140 -28
149 -52 4 -10 -12 -12 -72 -8 -110 7 -111 7 -106 16 3 4 -6 8 -20 8 -14 0 -26
-4 -26 -10 0 -5 -16 -10 -35 -10 -19 0 -35 5 -35 11 0 7 -11 8 -31 4 -17 -4
-43 -2 -60 6 -22 8 -33 9 -41 1 -8 -8 -20 -7 -44 3 -18 7 -56 12 -86 10 -29
-1 -61 1 -70 6 -35 19 -126 31 -228 30 l-105 0 43 20 c24 10 48 17 52 14 5 -3
19 -1 32 4 29 12 38 13 38 3z m13 -95 c10 -2 22 -8 25 -13 4 -5 41 -9 82 -8
41 0 80 -3 85 -6 6 -4 17 -8 25 -8 44 -3 226 -4 235 -1 6 1 24 0 40 -3 17 -4
56 -10 87 -13 44 -5 56 -10 51 -21 -6 -16 18 -19 33 -4 15 15 146 -4 199 -30
26 -13 53 -19 65 -15 11 4 20 2 20 -4 0 -6 -7 -11 -17 -11 -14 0 -14 -2 2 -20
22 -24 103 -50 157 -50 21 0 38 -5 38 -10 0 -7 -16 -9 -40 -6 -32 4 -40 2 -40
-11 0 -11 -8 -14 -30 -11 -24 4 -30 1 -25 -9 3 -8 5 -19 5 -24 0 -5 50 -9 110
-9 l110 0 0 81 0 80 -36 15 c-65 27 -28 37 123 32 122 -3 144 -6 188 -28 62
-31 85 -78 68 -141 l-10 -39 66 3 c53 2 66 -1 66 -13 0 -10 -11 -15 -32 -16
-18 0 -36 -3 -39 -7 -4 -4 -15 -7 -25 -7 -13 0 -16 -5 -12 -16 3 -9 9 -12 12
-7 3 5 27 8 53 7 27 0 45 3 42 8 -4 5 8 3 24 -6 17 -9 36 -16 42 -16 15 0 13
90 -2 91 -10 0 -10 2 0 6 7 3 12 26 12 63 0 63 -15 90 -51 90 -10 0 -19 7 -19
15 0 13 29 15 200 15 l200 0 0 -65 c0 -36 -2 -65 -5 -65 -2 0 -14 18 -25 39
-17 35 -26 41 -72 51 -29 6 -75 10 -103 8 l-50 -3 -3 -102 -3 -103 30 0 c48 0
82 18 94 50 6 17 17 30 24 30 10 0 13 -24 13 -95 0 -78 -3 -95 -15 -95 -8 0
-15 8 -15 18 0 29 -35 54 -85 60 l-46 5 2 -81 c1 -64 5 -82 18 -87 8 -3 17 -1
19 4 2 6 10 11 18 11 11 0 10 -5 -5 -21 -11 -12 -25 -18 -32 -14 -7 5 -10 3
-7 -6 11 -33 89 -48 167 -33 25 5 29 9 20 20 -9 12 -7 14 12 12 18 -2 27 5 41
35 22 46 38 48 38 5 0 -74 -11 -103 -42 -117 -17 -8 -33 -10 -37 -5 -9 13
-208 18 -216 5 -3 -7 2 -11 14 -11 11 0 22 -4 25 -9 3 -5 25 -7 48 -5 22 2 57
0 77 -6 74 -20 487 -31 621 -16 40 4 49 3 32 -4 -13 -5 -54 -11 -93 -12 -46
-2 -69 -7 -69 -15 0 -12 -36 -26 -56 -22 -5 1 -11 1 -14 0 -3 -1 -29 -4 -57
-6 -38 -3 -57 0 -68 11 -8 9 -15 12 -15 7 0 -11 -159 -8 -265 6 -44 5 -82 6
-85 1 -3 -4 -52 -6 -109 -4 -58 1 -112 -1 -120 -6 -18 -10 -14 -12 39 -15 38
-2 80 -16 80 -26 0 -5 7 -9 15 -9 8 0 15 -5 15 -11 0 -7 -9 -9 -22 -6 -27 7
-197 22 -423 37 -88 6 -216 18 -285 25 -69 8 -151 17 -184 20 -32 4 -100 18
-151 31 -137 35 -233 47 -289 34 -37 -8 -52 -7 -76 5 -16 8 -37 17 -47 18 -51
10 -73 21 -73 35 0 8 -3 12 -7 9 -3 -4 -12 -2 -19 4 -16 13 -17 11 -32 -84 -6
-37 -18 -79 -27 -93 -35 -53 -132 -68 -191 -29 -35 23 -30 69 8 73 20 2 30 -4
44 -27 12 -20 26 -31 41 -31 35 0 53 36 53 111 0 61 -2 67 -25 77 -14 7 -32
12 -40 12 -8 0 -15 4 -15 8 0 5 -6 9 -12 10 -50 6 -81 17 -103 38 -14 13 -30
24 -36 24 -5 0 -4 6 3 14 11 12 17 12 39 0 15 -7 29 -11 31 -8 3 3 18 -1 32
-8 22 -9 29 -9 35 0 6 10 19 10 50 3 35 -8 40 -7 30 5 -6 8 -20 14 -31 14 -30
0 -158 63 -158 78 0 8 -5 10 -12 6 -8 -5 -7 -1 2 10 13 17 12 21 -15 42 -17
13 -34 24 -38 24 -4 0 -20 9 -35 20 -15 10 -40 21 -55 24 -34 6 -59 25 -51 38
3 5 12 1 19 -9 7 -10 17 -15 20 -11 4 4 16 3 26 -2 12 -7 20 -6 24 1 9 13 52
3 72 -16 13 -13 34 -20 55 -16 5 0 7 -4 5 -11 -1 -6 6 -14 17 -16 11 -2 16 0
12 7 -9 14 9 14 35 1 15 -8 19 -7 19 4 0 9 -18 18 -47 24 l-48 10 39 1 39 1
-27 25 c-27 25 -60 32 -151 35 -28 1 -28 2 -10 12 11 6 23 16 26 21 4 5 20 7
35 4 l29 -5 -24 19 c-13 10 -28 19 -35 19 -13 1 -56 35 -56 45 0 15 52 55 71
55 10 0 19 5 19 11 0 7 11 9 33 5 17 -4 40 -8 50 -9z m884 -53 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m73 -20 c0 -2 -7 -4 -15 -4 -8 0 -15
4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m108 -12 c2 -7 -7 -12 -23 -12 -22
0 -24 2 -13 15 15 16 30 15 36 -3z m1399 -7 c2 -6 -7 -15 -19 -20 -32 -14 -27
-37 24 -111 l43 -65 23 28 c55 66 82 115 71 134 -5 11 -19 19 -30 19 -12 0
-19 5 -17 13 6 18 188 23 188 5 0 -7 -15 -20 -33 -28 -34 -16 -179 -188 -173
-205 2 -6 43 -63 90 -127 68 -91 94 -118 117 -123 16 -4 29 -13 29 -21 0 -11
-22 -14 -115 -14 -96 0 -115 3 -115 15 0 9 9 15 25 15 17 0 25 5 25 18 0 19
-109 187 -116 179 -23 -23 -124 -165 -124 -174 0 -7 11 -15 25 -19 14 -3 25
-12 25 -20 0 -11 -20 -14 -91 -14 -57 0 -88 -4 -84 -10 4 -6 -7 -10 -25 -10
-18 0 -29 4 -25 10 3 6 14 10 23 10 14 0 14 2 3 9 -10 7 -8 10 10 16 56 15 88
43 163 137 91 115 93 89 -15 235 -52 71 -74 93 -98 98 -17 4 -31 13 -31 21 0
12 19 14 112 12 64 -2 113 -7 115 -13z m-2640 -1 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m1231 -63 c11 -6 7 -10 -13 -15 -29 -7 -98 11 -90
23 5 9 83 3 103 -8z m112 5 c0 -15 -27 -29 -40 -21 -5 3 -7 12 -3 20 7 19 43
20 43 1z m6 -71 c11 -8 14 -15 7 -15 -7 0 -13 -7 -13 -15 0 -17 -16 -20 -25
-6 -3 5 -1 13 5 16 8 5 7 11 -1 21 -15 18 2 18 27 -1z m541 -51 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1844 -34 c7 -10 25 -22 40 -25 15
-4 24 -11 21 -17 -3 -5 -11 -4 -20 3 -7 6 -21 9 -29 5 -9 -3 -15 0 -15 9 0 8
-11 17 -25 21 -13 3 -29 11 -34 18 -8 10 -3 11 19 8 17 -2 36 -12 43 -22z
m-42 -57 c13 -17 13 -17 -6 -3 -11 8 -22 16 -24 17 -2 2 0 3 6 3 6 0 17 -8 24
-17z m2086 -169 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m303
-34 c-7 -4 -22 -5 -34 -2 -20 6 -20 6 4 12 29 8 48 1 30 -10z m-87 -57 c-7 -2
-21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m357 -3 c11 -8 3 -9 -27 -6
-24 3 -43 1 -43 -5 0 -11 -130 -12 -157 -1 -12 5 2 9 42 13 101 10 169 9 185
-1z m218 -36 c33 -9 27 -21 -8 -16 -16 2 -30 8 -30 13 0 10 7 11 38 3z m-768
-82 c0 -4 -17 -6 -37 -4 -59 5 -63 10 -10 11 26 1 47 -3 47 -7z m500 -16 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-537
-13 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m92 -3 c27 -7 25
-8 -17 -9 -27 0 -48 4 -48 9 0 11 21 11 65 0z m218 -17 c-13 -2 -35 -2 -50 0
-16 2 -5 4 22 4 28 0 40 -2 28 -4z m307 -9 c0 -2 -11 -4 -25 -4 -14 0 -25 4
-25 9 0 4 11 6 25 3 14 -2 25 -6 25 -8z m213 9 c-7 -2 -21 -2 -30 0 -10 3 -4
5 12 5 17 0 24 -2 18 -5z m382 -3 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0
13 -4 16 -10z"/>
<path d="M1844 2763 c10 -11 37 -12 30 -2 -3 5 -12 9 -21 9 -9 0 -13 -3 -9 -7z"/>
<path d="M2380 2681 l0 -89 78 3 c68 3 78 6 90 27 20 35 8 99 -23 125 -21 18
-38 23 -86 23 l-59 0 0 -89z"/>
<path d="M1094 2689 c-3 -5 6 -9 20 -9 31 0 34 6 6 13 -11 3 -23 1 -26 -4z"/>
<path d="M1750 2692 c-16 -6 -1 -22 20 -22 19 0 47 -36 36 -47 -2 -3 -20 0
-38 5 -29 10 -89 16 -126 13 -7 -1 -10 -5 -7 -11 3 -5 1 -10 -4 -10 -19 0 -12
-20 7 -21 13 0 12 -2 -5 -9 -13 -5 -23 -14 -23 -20 0 -9 39 -15 64 -11 6 1 20
-3 32 -10 24 -12 59 -5 50 10 -3 5 -21 12 -40 15 -19 4 -37 14 -40 23 -9 23
-3 26 40 14 77 -20 165 -25 175 -10 6 9 12 10 17 4 4 -5 26 -11 49 -13 l42 -2
-9 31 c-13 44 -25 56 -68 65 -38 7 -154 12 -172 6z m115 -42 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m8 -37 c-7 -2 -21 -2
-30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M1160 2666 c14 -14 82 -18 78 -4 -2 5 -23 11 -46 12 -29 2 -39 -1
-32 -8z"/>
<path d="M1320 2640 c0 -5 14 -7 33 -4 24 4 28 3 17 -5 -12 -8 -8 -11 17 -11
17 0 34 -4 38 -10 3 -5 12 -7 19 -4 8 3 17 1 21 -5 3 -6 19 -11 36 -11 25 0
29 4 29 28 l0 27 -105 2 c-63 2 -105 -1 -105 -7z"/>
<path d="M2735 2590 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M2810 2460 c-6 -11 -20 -20 -30 -20 -33 0 -23 -27 13 -33 42 -6 47
-2 47 39 0 38 -14 44 -30 14z"/>
<path d="M1320 2400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2126 2401 c-14 -22 6 -29 89 -29 65 1 85 4 85 14 0 10 -17 14 -62
14 -35 0 -73 3 -84 6 -12 3 -25 1 -28 -5z"/>
<path d="M2527 2392 c-9 -2 -15 -8 -12 -13 4 -5 -1 -9 -9 -9 -9 0 -16 3 -16 8
0 4 -25 8 -56 9 -40 2 -54 -1 -51 -10 2 -6 14 -11 26 -10 11 2 21 -2 21 -8 0
-8 4 -8 14 0 10 8 16 9 21 1 7 -11 146 -12 185 -1 21 7 16 10 -35 20 -33 7
-62 14 -65 15 -3 2 -13 1 -23 -2z"/>
<path d="M2805 2350 c-3 -5 -17 -10 -30 -10 -29 0 -41 -16 -19 -24 30 -12 63
-6 73 13 14 25 -9 46 -24 21z"/>
<path d="M1490 2300 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2480 2299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
</g>
`]
